import React from "react";
import { Link } from "gatsby";
import { Typography, TypographyVariants } from "../components/Typography";
import { Page } from "../components/Page";

const NotFoundPage = () => {
/*   return (
    <Layout>
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  ); */

  return (
    <Page pageTitle="404">
      <div className="page-content">
        <div className="main sdv-layout-block">
          <Typography variant={TypographyVariants.H1}>This is not the page you're looking for...</Typography><Typography variant={TypographyVariants.H2}><Link to="/" className="sdv-link">Return Home</Link></Typography>
        </div>
      </div>
    </Page>
  );
};

export default NotFoundPage
